import { invariant } from "@epic-web/invariant";
import type { Dispatch, ReactNode } from "react";
import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import type { ZDAuthenticatedUser } from "~/types/zipdeal.types.ts";
import { useLanguageSwitcher } from "~/zd-ui/src/hooks/useLanguageSwitcher";
import { ResetPasswordModal } from "~common/components/ResetPasswordModal.tsx";
import { useThingFromMatches } from "~hooks/useThingFromMatches.tsx";
import { useRootLoaderData } from "~loaders/useRootLoaderData.tsx";
import { useAuthFetcher } from "~routes/api-/auth-/authenticatedUser/fetcher.ts";

type AlwaysUser = ZDAuthenticatedUser;

interface Auth {
	user?: AlwaysUser;
	signInLoading?: boolean;
	signOut?: () => void;
	authenticateUser?: () => void;
	hasRole?: (any) => boolean;
	roles?: string[];
}

const noUser: ZDAuthenticatedUser = {
	isSuperuser: false,
	roles: [],
	firstName: "",
	lastName: "",
	email: "",
	id: "",
	clientuserprofile: {
		id: "",
	},
};

const AuthContext = createContext<Auth>({
	user: noUser,
	signInLoading: false,
	signOut: () => {},
	authenticateUser: () => {},
	hasRole: () => false,
	roles: [],
});

function useProvideAuth() {
	const { user } = useRootLoaderData();

	const _loginUrl = useThingFromMatches("_loginUrl", { defaultValue: null });

	const fetcher = useAuthFetcher();
	const { submitDataWithPromise } = fetcher;

	const authenticateUser = useCallback(
		async (logout = false) => {
			await submitDataWithPromise({
				logout,
				_loginUrl,
			});
		},
		[submitDataWithPromise, _loginUrl],
	);

	const authenticateUserLoading = fetcher.state === "loading";

	const hasRole = useCallback(
		(role) => {
			return user?.roles.includes(role);
		},
		[user?.roles],
	);

	const signOut = useCallback(async () => {
		await authenticateUser(true);
	}, [authenticateUser]);

	// Return the user object and auth methods

	return {
		user,
		loading: false,
		signInLoading: authenticateUserLoading,
		signOut,
		authenticateUser,
		roles: user?.roles || [],
		hasRole,
	};
}

export const ProvideAuth = ({
	children,
}: {
	children?: ReactNode;
	initialUser: ZDAuthenticatedUser | undefined;
}) => {
	// It is VERY important that one and only one ProvideAuth appear in the Component Tree!!!
	// Otherwise we'll have conflicting sources of truth.
	// console.log({ useProvideAuth: initialUser });
	const auth = useProvideAuth();
	useLanguageSwitcher(); // FIXME: put this in its own provider
	const requiresPasswordReset =
		auth.user?.clientuserprofile?.requiresPasswordReset;

	return (
		<AuthContext.Provider value={auth}>
			{children}
			{requiresPasswordReset ? <ResetPasswordModal /> : <></>}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	invariant(AuthContext, "useAuth must be used within a ProvideAuth");
	return useContext(AuthContext);
};

export const createRedirectUrlFromRequest = (
	loginPath,
	request,
	redirectParam = "redirectTo",
	pathOverride: string | undefined = undefined,
) => {
	const url = new URL(request.url);

	url.searchParams.delete(redirectParam);
	const search = url.search;

	const encoded = encodeURIComponent(search ? `${search}` : "");

	return `${loginPath}?${redirectParam}=${
		pathOverride || url.pathname
	}${encoded}`;
};
