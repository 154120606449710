import React from "react";
import { useIsValid } from "remix-validated-form";
import { object, ref, string } from "yup";
import { useResetPasswordFetcher } from "~routes/api-/accounts-/reset-password/fetcher.ts";
import {
	ZipButton,
	ZipModal,
	ZipPortal,
	ZipTextInput,
	useValidatedForm,
} from "~zd-ui";

const schema = object().shape({
	newPass: string()
		.required("New password is required")
		.min(9, "Password needs to be at least 9 characters long."),
	confirmPass: string()
		.oneOf([ref("newPass")], "Passwords do not match")
		.required("Password is required"),
});
export const ResetPasswordModal = () => {
	const fetcher = useResetPasswordFetcher();

	const { data, submit, state }: { data; submit; state } = fetcher;

	const validationErrors = data?.updatePassword?.errors;

	const defaultValues = {
		newPass: "",
		confirmPass: "",
	};
	const formId = "password-reset";

	const { validatedFormNode } = useValidatedForm(
		formId,
		schema,
		defaultValues,
		false,
		{
			onSubmit: (values, event) => {
				event.preventDefault();
				const form = event.target;

				const formData = new FormData(form as HTMLFormElement);

				submit(formData);
			},
		},
	);

	const isValid = useIsValid(formId);
	return (
		<ZipPortal>
			<ZipModal>
				<ZipModal.Header>It’s Time to Change Your Password</ZipModal.Header>
				<ZipModal.Content>
					{validatedFormNode}
					<input type="hidden" name="remember" value="true" />
					<div className="">
						<div className={"w-128 col-span-3 m-auto py-4 text-gray-500"}>
							Passwords need to be at least 9 characters in length. They must
							also include at least one number.{" "}
						</div>
						<div className={"w-128 m-auto"}>
							{validationErrors && (
								<div className={"text-zd-red-500 mt-1 text-sm"}>
									{validationErrors}
								</div>
							)}
						</div>
						<div className={"w-128 m-auto"}>
							<ZipTextInput
								formId={formId}
								name="newPass"
								type="password"
								label={false}
								placeholder={"New Password"}
								confirmationBorder={true}
							/>
						</div>
						<div className={"w-128 m-auto mt-4"}>
							<ZipTextInput
								formId={formId}
								name="confirmPass"
								type="password"
								label={false}
								placeholder={"Confirm Password"}
								confirmationBorder={true}
							/>
						</div>
					</div>
					<span className={"mt-4 inline-block align-middle"}>
						<ZipButton
							formId={formId}
							isLoading={state !== "idle"}
							isDisabledButton={Boolean(!isValid)}
							type={"submit"}
						>
							Update password
						</ZipButton>
					</span>
				</ZipModal.Content>
			</ZipModal>
		</ZipPortal>
	);
};
