import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useZipSearchParam2 } from "~zd-ui";

export const useLanguageSwitcher = () => {
	const { value: lng } = useZipSearchParam2("lng");

	const { i18n } = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(lng);
	}, [i18n, lng]);
};
